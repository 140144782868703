import React from 'react';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons'; // Import Ant Design icons

const NotFound = ({ element, allowOnMobile }) => {


    return (
      <div 
        className="flex flex-col justify-center items-center text-center h-screen"
      >
  

        <CloseOutlined 
          style={{ fontSize: '40px', color: 'rgb(127, 40, 248)' }} // Icon for desktop requirement

          className="mb-4"
        />
        <h2 className="font-bold text-lg md:text-xl lg:text-2xl">
          Sorry this page Doesn't exist :)
        </h2>
        <InfoCircleOutlined 
          style={{ fontSize: '20px', color: 'rgb(127, 40, 248)', marginTop: '20px' }} // Additional info icon

        />

        <p className="text-base md:text-lg lg:text-xl mt-4 max-w-md">
          Are you lost ? Navigate to the Leads
        </p>

        <a
		  href='/user/leads'
          
            className="inline-flex items-center px-4 py-2 text-white border border-['rgb(127, 40, 248)']  rounded-full hover:bg-indigo-700 focus:outline-none focus:ring" style={{backgroundColor:'rgb(127, 40, 248)',}}>
          
                <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mr-2" fill="white" viewBox="0 0 24 24"
                stroke="white" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
              </svg>
      
	  		
                <a className="text-sm font-medium text-white" >
                  <span className='text-white'>
                  Return to the leads

                    </span>
              </a>
		
          </a>

        
 
      </div>
    );


};

export default NotFound;
