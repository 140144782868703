import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card } from 'antd';
import {
  HourglassOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import config from '../../config';

export default function Overview() {
  const [unassignedCount, setUnassignedCount] = useState(0);
  const [assignedCount, setAssignedCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from local storage
        const response = await axios.get(`${config.baseUrl}/administration/team/summary-data`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        const data = response.data;

        // Update state with the fetched data
        setUnassignedCount(data.unassigned_count);
        setAssignedCount(data.assigned_count);
        setClosedCount(data.closed_count);

        // Format pie chart data
        const formattedPieData = data.assignee_counts.map((item) => ({
          name: item.assignee,
          value: item.count,
          color: getRandomColor(),
        }));
        setPieData(formattedPieData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Generate random colors for pie chart slices
  const getRandomColor = () => {
    const baseColor = [127, 40, 248]; // RGB for #7F28F8
    const variation = 30; // Adjust this value to control the range of color variation
  
    const getRandomValue = (base) => {
      const min = Math.max(0, base - variation); // Ensure the value doesn't go below 0
      const max = Math.min(255, base + variation); // Ensure the value doesn't exceed 255
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
  
    const r = getRandomValue(baseColor[0]);
    const g = getRandomValue(baseColor[1]);
    const b = getRandomValue(baseColor[2]);
  
    return `rgb(${r}, ${g}, ${b})`;
  };
  
  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
        {/* Unassigned Card */}
        <Card
          className="shadow-md hoverable"
          actions={[<EllipsisOutlined key="ellipsis" />]}
        >
          <div className="flex items-center space-x-4">
            <HourglassOutlined className="text-4xl text-purple-500" />
            <div>
              <h3 className="text-lg font-medium">Unassigned</h3>
              <p className="text-2xl font-bold">{unassignedCount} Sessions</p>
            </div>
          </div>
        </Card>

        {/* Assigned Card */}
        <Card
          className="shadow-md hoverable"
          actions={[<EllipsisOutlined key="ellipsis" />]}
        >
          <div className="flex items-center space-x-4">
            <CheckCircleOutlined className="text-4xl text-blue-500" />
            <div>
              <h3 className="text-lg font-medium">Assigned</h3>
              <p className="text-2xl font-bold">{assignedCount} Sessions</p>
            </div>
          </div>
        </Card>

        {/* Completed Card */}
        <Card
          className="shadow-md hoverable"
          actions={[<EllipsisOutlined key="ellipsis" />]}
        >
          <div className="flex items-center space-x-4">
            <TeamOutlined className="text-4xl text-green-500" />
            <div>
              <h3 className="text-lg font-medium">Completed</h3>
              <p className="text-2xl font-bold">{closedCount} Sessions</p>
            </div>
          </div>
        </Card>
      </div>

      {/* Pie Chart Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <Card
          className="shadow-md hoverable"
          title="Total Task by Assignee"
          extra={<span className="text-gray-400">Refreshed a moment ago</span>}
        >
          <div className="flex justify-center">
            <PieChart width={400} height={400}>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={2}
                dataKey="value"
                startAngle={90}
                endAngle={-270}
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend verticalAlign="bottom" />
            </PieChart>
          </div>
        </Card>
      </div>
    </div>
  );
}
