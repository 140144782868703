import React, { useState, useEffect } from "react";
import { Layout, Menu, Card, Row, Col, Typography, Table, Spin, message } from "antd";
import { DashboardOutlined, LogoutOutlined } from "@ant-design/icons";
import config from "../../config";

const { Header, Content, Sider } = Layout;
const { Title, Text } = Typography;

const AffiliateDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [affiliateData, setAffiliateData] = useState(null);

  const fetchDashboardData = async () => {
    try {
      const response = await fetch(`${config.baseUrl}/affiliate/dashboard`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("affiliateToken")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch dashboard data.");
      }

      const data = await response.json();
      setAffiliateData(data);
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const logout = () => {
    localStorage.removeItem("affiliateToken");
    window.location.href = "/program/affiliate/login";
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
    {
      title: "Signups",
      dataIndex: "signups",
      key: "signups",
    },
    {
      title: "Earnings",
      dataIndex: "earnings",
      key: "earnings",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider theme="light" breakpoint="lg" collapsedWidth="0">
        <div className="logo" style={{ padding: "16px", textAlign: "center" }}>
          <Title level={4}>Jaweb Affiliate</Title>
        </div>
        <Menu mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1" icon={<DashboardOutlined />}>Dashboard</Menu.Item>
          <Menu.Item key="4" icon={<LogoutOutlined />} onClick={logout}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: "#fff", padding: "0 16px" }}>
          <Title level={3} style={{ margin: 0 }}>Affiliate Dashboard</Title>
        </Header>
        <Content style={{ margin: "16px" }}>
          {loading ? (
            <Spin size="large" style={{ display: "block", margin: "auto" }} />
          ) : (
            <>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={8}>
                  <Card title="Clicks" bordered={false} style={{ textAlign: "center" }}>
                    <Text strong style={{ fontSize: "24px" }}>
                      {affiliateData?.clicks || 0}
                    </Text>
                  </Card>
                </Col>
                <Col xs={24} md={8}>
                  <Card title="Signups" bordered={false} style={{ textAlign: "center" }}>
                    <Text strong style={{ fontSize: "24px" }}>
                      {affiliateData?.signups || 0}
                    </Text>
                  </Card>
                </Col>
                <Col xs={24} md={8}>
                  <Card title="Earnings" bordered={false} style={{ textAlign: "center" }}>
                    <Text strong style={{ fontSize: "24px" }}>
                      ${affiliateData?.earnings || "0.00"}
                    </Text>
                  </Card>
                </Col>
              </Row>

              <Card title="Performance" style={{ marginTop: "16px" }}>
                <Table
                  dataSource={affiliateData?.performance || []}
                  columns={columns}
                  rowKey="date"
                  pagination={{ pageSize: 5 }}
                />
              </Card>

              <Card title="Your Referral Link" style={{ marginTop: "16px" }}>
                <Text copyable>{affiliateData?.referral_link || "Loading..."}</Text>
              </Card>
            </>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AffiliateDashboard;
