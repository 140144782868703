import React, { useState, useEffect } from 'react';
import { Card, List, Button, Modal, Input, message, Avatar, Tag } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import avatar from './WhatsApp Image 2025-01-15 at 10.10.51.jpeg'

export default function TeamMembers({ teamLead, teamMembers, isLoading, fetchTeamData, roomChat }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [teamName, setTeamName] = useState('');
  const [isPaying, setIsPaying] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const isShopifyUser = localStorage.getItem('Userdata')?.mode === 'shopify';
  const isEmail=localStorage.getItem('Userdata')?.email
  const isInvited = localStorage.getItem('invited') === 'true'; // Invited user flag

  // Check for payment status on page load
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const chargeId = searchParams.get('charge_id');
    const emailFromUrl = searchParams.get('email');
    const teamNameFromUrl = searchParams.get('team_name');

    if (sessionId) {

      setEmail(emailFromUrl || '');
      setTeamName(teamNameFromUrl || '');
      verifyStripePayment(sessionId,emailFromUrl,teamNameFromUrl);

    } else if (chargeId) {
      const emailFromUrl = searchParams.get('email');
      console.log(emailFromUrl)
      const teamNameFromUrl = searchParams.get('team_name');
    
      setEmail(emailFromUrl || '');
      setTeamName(teamNameFromUrl || '');

      verifyShopifyPayment(chargeId,emailFromUrl,teamNameFromUrl);
    }
  }, [location.search]);

  const verifyStripePayment = async (sessionId,email,teamNameFromUrl) => {
    try {
      const response = await axios.post(`${config.baseUrl}/administration/team/stripe/verify-payment/`, {
        session_id: sessionId,
      },    
       {
        headers: {
          Authorization: `Token ${token}`, // Add authorization header
        },
      });
      if (response.data.success) {
        setPaymentCompleted(true);
        message.success('Payment verified! Adding member...');
        await addMember(sessionId,email,teamNameFromUrl);
        navigate(location.pathname, { replace: true });
      } else {
        message.error(response.data.message || 'Payment verification failed.');
      }
    } catch (error) {
      message.error('Error verifying payment.');
    }
  };

  const verifyShopifyPayment = async (chargeId,email,teamNameFromUrl) => {
    try {
      const response = await axios.post(`${config.baseUrl}/administration/team/shopify/verify-payment/`, {
        charge_id: chargeId,
      },
      {
        headers: {
          Authorization: `Token ${token}`, // Add authorization header
        },
      });
      if (response.data.success) {
        setPaymentCompleted(true);
        message.success('Payment verified! Adding member...');
        await addMember(chargeId,email,teamNameFromUrl);
        navigate(location.pathname, { replace: true });
      } else {
        message.error(response.data.message || 'Payment verification failed.');
      }
    } catch (error) {
      message.error('Error verifying payment.');
    }
  };


  const addMember = async (paymentId,email,teamNameFromUrl) => {  
    console.log(paymentId)
    console.log(email)      
    
    const key = 'updatable';
    if (!email || !paymentId) {
      message.error('Email and payment are required.');
      return;
    }
  
    try {
      message.loading({ content: 'Adding a member...', key });

      const response = await axios.post(
        `${config.baseUrl}/administration/team/`,
        {
          email,
          team_name: teamNameFromUrl, // Include team name if applicable
          payment_id: paymentId, // Send the payment ID
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
  
      if (response.data.message === "Invitation sent successfully") {
        message.success({ content: 'Team member added successfully.!', key, duration: 2 });

        fetchTeamData(); // Refresh team data after success
      } else {
        message.error(response.data.error || 'Failed to add team member.');
      }
    } catch (error) {
      message.error(error.response?.data?.error || 'Failed to add team member.');
    }
  };
  

  const handlePayment = async () => {
    if ((!teamName && teamMembers.length === 0 && !roomChat) || !email) {
      message.error('All required fields must be filled.');
      return;
    }

    try {
      setIsPaying(true);

      if (isShopifyUser) {
        const shopifyResponse = await axios.post(
          `${config.baseUrl}/administration/team/shopify/billing/`,
          { action: 'invite_member', team_name: teamName,email },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        if (shopifyResponse.data.url) {
          window.location.href = shopifyResponse.data.url;
        } else {
          message.error('Failed to initiate Shopify billing.');
        }
      } else {
        const stripeResponse = await axios.post(
          `${config.baseUrl}/administration/team/stripe/create-checkout-session/`,
          { isEmail, team_name: teamName,email },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        if (stripeResponse.data.url) {
          window.location.href = stripeResponse.data.url;
        } else {
          message.error('Failed to initiate payment.');
        }
      }
    } catch (error) {
      message.error('Error initiating payment.');
    } finally {
      setIsPaying(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Team Members</h2>
        {!isInvited && (
          <Button
            type="primary"
            style={{ backgroundColor: '#7F28F8', color: 'white' }}
            onClick={() => setIsModalOpen(true)}
          >
            Invite Member
          </Button>
        )}
      </div>

      <Card className="shadow-md hoverable">
        <List
          itemLayout="horizontal"
          loading={isLoading}
          dataSource={teamMembers}
          renderItem={(member) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={member.user?.image || avatar} />}
                title={
                  <div>
                    {member.user?.email || member.invitation?.[0]?.invitee_email || 'Invitee Email'}
                    <Tag
                      className="ml-2"
                      color={member.invitation?.[0]?.status === 'accepted' ? 'green' : 'orange'}
                    >
                      {member.invitation?.[0]?.status || 'Pending'}
                    </Tag>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Card>

      {/* Team Lead Section */}
      {isInvited && teamLead && (
        <div className="mt-4">
          <h3 className="text-md font-semibold">Team Lead</h3>
          <div className="flex items-center gap-3">
            <Avatar src={teamLead.image || avatar} />
            <span className="font-medium">{teamLead.email}</span>
          </div>
        </div>
      )}

      <Modal
        title="Invite Member"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handlePayment}
            loading={isPaying}
          >
            {isPaying ? 'Processing Payment...' : 'Pay & Invite'}
          </Button>,
        ]}
      >
        {teamMembers.length === 0 && !roomChat && (
          <Input
            type="text"
            placeholder="Enter team name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            className="mb-4"
          />
        )}
        <Input
          type="email"
          placeholder="Enter member's email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-4"
        />
      </Modal>
    </div>
  );
}
