import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Avatar, List, Tag, Dropdown, Upload, message as antdMessage, notification } from "antd";
import { PaperClipOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import config from "../../config"; // Using the provided baseUrl

const TeamChat = ({ membersParsed, roomChat }) => {

  console.log(membersParsed)
  const [members, setMembers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [mentions, setMentions] = useState([]);
  const [mentionDropdownVisible, setMentionDropdownVisible] = useState(false);
  const [isLoading,setIsLoading]=useState(false)
  const [socket, setSocket] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const userEmail = JSON.parse(localStorage.getItem("Userdata")).email;
  const token = localStorage.getItem("token");


  useEffect(() => {
    const acceptedMembers = membersParsed?.filter(
      (member) => member.invitation?.[0]?.status === "accepted"
    );
    setMembers(acceptedMembers || []);
  }, [membersParsed]);


  // Initialize WebSocket Connection
  useEffect(() => {
    if (roomChat){
      const ws = new WebSocket(`wss://jawebcrm.onrender.com/ws/roomchat/${roomChat}/`);
      ws.onopen = () => {
        console.log("WebSocket connected");
        const onlineMessage = {
          type: "user_online",
          email: userEmail,
        };
        ws.send(JSON.stringify(onlineMessage));
      };
  
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(data)
  
        if (data.user.email !== userEmail ){
          if (data.type === "chat_message") {
            setMessages((prevMessages) => [...prevMessages, data]);
            if (data.user !== userEmail) {
              showInAppNotification(data);
            }
          }
        }
    
      };
  
      ws.onclose = () => console.log("WebSocket disconnected");
      ws.onerror = (error) => console.error("WebSocket error:", error);
  
      setSocket(ws);
  
      return () => ws.close();
    }


  }, []);

  // Fetch messages from API (for initial load)
  useEffect(() => {
    const fetchMessages = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(`${config.baseUrl}/administration/team/chat/${roomChat}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setMessages(data);
          setIsLoading(false)
        } else {
          console.error("Failed to fetch messages", await response.text());
          setIsLoading(false)

        }
      } catch (error) {
        console.error("Error fetching messages:", error);
        setIsLoading(false)
      }
    };

    fetchMessages();
  }, [token]);

  // Send Message
  const handleSendMessage = async () => {
    if (!inputValue.trim()) {
      antdMessage.error("Message cannot be empty.");
      return;
    }

    const newMessage = {
      room_name: "Aiboys",
      message: inputValue,
      user: userEmail,
      timestamp: new Date().toISOString(),
      mentions,
      type:"send_message"
    };

    // Optimistically update the UI
    setMessages((prevMessages) => [
      ...prevMessages,
      { ...newMessage, timestamp: new Date().toISOString() },
    ]);

    if (socket) {
      socket.send(JSON.stringify(newMessage));
    }

    // Reset input and mentions
    setInputValue("");
    setMentions([]);

    // Save the message to the database via API
    try {
      const response = await fetch(`${config.baseUrl}/administration/team/chat/${roomChat}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(newMessage),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const savedMessage = await response.json();

      // Update the message with server-saved details (if necessary)
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.timestamp === newMessage.timestamp ? { ...savedMessage } : msg
        )
      );
    } catch (error) {
      console.error("Error saving message:", error);
      antdMessage.error("Failed to save message.");
    }

    // Broadcast the message via WebSocket for real-time updates
 
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const mentionTriggerIndex = value.lastIndexOf("@");
    if (mentionTriggerIndex !== -1 && value[mentionTriggerIndex + 1]) {
      setMentionDropdownVisible(true);
    } else {
      setMentionDropdownVisible(false);
    }
  };

  const handleSelectMention = (member) => {
    const mentionTriggerIndex = inputValue.lastIndexOf("@");
    const beforeMention = inputValue.slice(0, mentionTriggerIndex);
    setInputValue(`${beforeMention}@${member.user?.email || member.user?.name} `);
    setMentionDropdownVisible(false);
    setMentions([...mentions, member]);
  };

  const showInAppNotification = (message) => {
    api.open({
      message: "New Message",
      description: `${message.user.first_name}: ${message.message}`,
     duration:3
    });
  };

  const mentionMenu = (
    <div style={{ maxHeight: "200px", overflowY: "auto", background: "#fff" }}>
      {members.map((member) => (
        <div
          key={member.id}
          onClick={() => handleSelectMention(member)}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "5px 10px",
            cursor: "pointer",
            gap: "10px",
          }}
        >
          <Avatar src={member.user?.image || ""} size="small" />
          <span>{member.user?.email || member.user?.name}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      {contextHolder}

      <h2>Team Chat</h2>
      <div style={{ display: "flex", padding: "20px", width: "100%", alignItems: "center" }}>
        <div style={{ flex: 3, paddingRight: "20px" }}>
          <div
            style={{
              border: "1px solid #f0f0f0",
              borderRadius: "8px",
              padding: "20px",
              background: "#fff",
              height: "570px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ flex: 1, overflowY: "auto", marginBottom: "20px" }}>
              <List
                dataSource={messages}
                loading={isLoading}
                renderItem={(message) => (
                  <List.Item style={{ display: "flex", justifyContent: "unset", gap: 10 }}>
                    <Avatar src={message.user?.image || ""} style={{ marginRight: "10px" }} />
                    <div>
                      <strong>{message.user.first_name}</strong>{" "}
                      <span style={{ color: "#aaa", fontSize: "12px" }}>
                        {new Date(message.timestamp).toLocaleTimeString()}
                      </span>
                      <div style={{ marginTop: "5px" }}>
                        {message.message.split(" ").map((word, idx) =>
                          word.startsWith("@") ? (
                            <Tag key={idx} color="blue">
                              {word}
                            </Tag>
                          ) : (
                            `${word} `
                          )
                        )}
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px", position: "relative" }}>
              <Dropdown
                overlay={mentionMenu}
                visible={mentionDropdownVisible}
                trigger={["click"]}
                placement="topLeft"
                overlayClassName="bg-gray-300 text-black rounded"
              >
                <Input.TextArea
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Type @ to mention a member..."
                  rows={1}
                  style={{ resize: "none", flex: 1 }}
                  onClick={() =>
                    inputValue.includes("@") && setMentionDropdownVisible(true)
                  }
                />
              </Dropdown>
              {/* <Upload showUploadList={false}>
                <Button icon={<PaperClipOutlined />} />
              </Upload> */}
              <Button
                type="primary"
                icon={<SendOutlined />}
                onClick={handleSendMessage}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            border: "1px solid #f0f0f0",
            borderRadius: "8px",
            background: "#fff",
            padding: "10px",
            height: "570px",
            overflowY: "auto",
          }}
        >
          <h3>Team Members</h3>
          {members.map((member) => (
            <div
              key={member.id}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                gap: "10px",
              }}
            >
              <Avatar src={member.user?.image || ""} size="small" />
              <span>{member.user?.email || member.user?.name}</span>
              {member.isTeamLead && <Tag color="gold">Team Lead</Tag>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamChat;
