import React, { useEffect, useState } from 'react';
import CustomLayout from '../../layouts/Customlayout';
import SettingOption from './Settingsoptions';
import { Card, Button, message, Avatar } from 'antd'; // Import Avatar from Ant Design
import { useNavigate, useLocation } from 'react-router-dom'; // Use useNavigate and useLocation
import config from '../../config';

export default function Instagram() {
  const navigate = useNavigate(); // Use useNavigate
  const location = useLocation(); // Use useLocation to track the URL changes
  const [isConnected, setIsConnected] = useState(false);
  const [instagramInfo, setInstagramInfo] = useState(null);
  const [loading, setLoading] = useState(false); // For loading state
  const [error, setError] = useState(null); // For error handling

  // Check if the user is already connected when the component mounts
  useEffect(() => {
    fetch(`${config.baseUrl}/instagram/exchange-instagram-code/`, {
      method: 'GET',
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`, // If you're using token-based auth
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.connected) {
          setIsConnected(true);
          setInstagramInfo(data);
        }
      })
      .catch(error => {
        console.error('Error fetching Instagram info:', error);
      });
  }, []);

  // Handle Instagram OAuth flow when redirected with a code
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search); // Use location.search to track URL params
    const code = urlParams.get('code'); // Get the code from the URL

    if (code) {
      // Exchange the code for an access token
      setLoading(true);
      fetch(`${config.baseUrl}/instagram/exchange-instagram-code/`, {
        method: 'POST',
        body: JSON.stringify({ code }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`, // If you're using token-based auth
        },
      })
        .then(response => response.json())
        .then(data => {
          setLoading(false);
          message.success("Instagram connected successfully!", 3);
          setIsConnected(true);
          setInstagramInfo(data);
        })
        .catch(error => {
          setLoading(false);
          setError('Failed to connect Instagram');
          console.error('Error exchanging Instagram code:', error);
        });
    }
  }, [location]); // Trigger effect when location changes

  const handleConnectInstagram = () => {
    const facebookOAuthUrl = `https://www.facebook.com/v14.0/dialog/oauth?client_id=1545212436228133&redirect_uri=https://crm.jaweb.me/integration/instagram&response_type=code&scope=public_profile,pages_show_list,business_management,pages_messaging,instagram_basic,instagram_manage_comments,instagram_content_publish,instagram_manage_messages,pages_manage_metadata,pages_read_user_content`;
    window.location.href = facebookOAuthUrl;
  };

  return (
    <CustomLayout>
      <div className="flex flex-col md:flex-row gap-6 justify-center ">
        <div className="md:w-1/6">
          <SettingOption />
        </div>

        <div className="flex-1">
          <Card className="p-2">
            <div className="flex flex-col items-center text-center ">
              <Avatar
                src={instagramInfo?.profile_picture || "https://img.freepik.com/free-vector/instagram-icon_1057-2227.jpg"}
                size={100} // Size of the Avatar
                className="mb-4"
                alt="Instagram Logo"
              />
              <h2 className="text-xl font-semibold mb-2">
                {isConnected ? 'Instagram Connected' : 'Connect Instagram for Messaging'}
              </h2>

              <p className="text-gray-600 mb-6">
                {isConnected
                  ? `Instagram Account ID: ${instagramInfo?.user_id},  Name: ${instagramInfo?.name}`
                  : `Reply to messages, manage customer conversations, and build better relationships with your Instagram audience. Connect your Instagram Business Account to get started!`}
              </p>

              <div className="text-left mb-6 ">
                {loading && <p>Connecting...</p>}
                {error && <p style={{ color: 'red' }}>{error}</p>}

                {!isConnected ? (
                  <>
                    <ul className="list-disc list-inside text-gray-600">
                      <li>Receive and reply to messages in real-time.</li>
                      <li>Organize customer interactions efficiently.</li>
                      <li>Build automated responses for faster communication.</li>
                    </ul>
                    <div className='flex justify-center'>
                      <Button
                        type="primary"
                        size="large"
                        onClick={handleConnectInstagram}
                        className="bg-blue-500 hover:bg-blue-600 border-none text-white"
                        disabled={loading || isConnected}
                      >
                        {loading ? 'Connecting...' : 'Connect Instagram Now'}
                      </Button>
                    </div>
                  </>
                ) : (
                  <Button
                    type="default"
                    size="large"
                    onClick={() => alert('Instagram already connected!')}
                    className="bg-gray-500 hover:bg-gray-600 text-white"
                  >
                    Already Connected
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </CustomLayout>
  );
}
