import React from 'react';
import { Menu, Tag } from 'antd';  
import { Link, useLocation } from 'react-router-dom';

const SettingOption = () => {
  const location = useLocation();
  const locationPath = location.pathname.split('/');
  const currentPath = locationPath[locationPath.length - 1];

  var UserInfo = JSON.parse(localStorage.getItem('UserObject'));
  const showOnlyShopify = UserInfo?.mode === 'shopify';

  const menuOptions = [
    {
      key: 'Website',
      label: 'Website',
      icon: 'https://jawebstorage.us-iad-1.linodeobjects.com/images/Website_gambar-removebg-preview.png',
      path: '/integration/website',
    },
    {
      key: 'Wordpress',
      label: 'Wordpress',
      icon: 'https://jawebstorage.us-iad-1.linodeobjects.com/images/wordpress-icon.svg',
      path: '/integration/wordpress',
    },
    {
      key: 'Shopify',
      label: 'Shopify',
      icon: 'https://jawebstorage.us-iad-1.linodeobjects.com/images/shopify.svg',
      path: '/integration/shopify',
    },
    {
      key: 'WhatsApp',
      label: 'WhatsApp',
      icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png',
      path: '/integration/whatsapp',
    },
    {
      key: 'Zapier',
      label: 'Zapier',
      icon: 'https://www.good2gosoftware.com/wp-content/uploads/2023/02/2560px-Zapier_logo.svg_-2048x938.png',
      path: '/integration/zapier',
    },
    {
      key: 'Calendly',
      label: 'Calendly',
      icon: 'https://logosandtypes.com/wp-content/uploads/2022/09/calendly.svg',
      path: '/integration/calendly',
    },
    {
      key: 'Instagram',
      label: 'Instagram',
      icon: 'https://img.freepik.com/free-vector/instagram-icon_1057-2227.jpg?t=st=1736945079~exp=1736948679~hmac=f14de11a25b44067409a4860db97f4e6b382869402b0f523c4d5e5d9136fe25c&w=826',
      path: '/integration/instagram',
    },
  ];

  const filteredMenuOptions = showOnlyShopify
    ? menuOptions.filter(option => ['Shopify', 'WhatsApp', 'Zapier', 'Calendly'].includes(option.key))
    : menuOptions.filter(option => option.key !== 'Shopify');

  return (
    <Menu mode="inline" selectedKeys={[currentPath]}>
      {filteredMenuOptions.map(option => (
        <Menu.Item key={option.key} icon={<img src={option.icon} alt={option.label} style={{ width: '1.5rem' }} />}>
          <Link to={option.path}>
            {option.label}
            {(option.key === "Instagram" || option.key === "Calendly") && (
              <Tag color="success" style={{ marginLeft: '6px' }}>New</Tag>
          )}         
           </Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default SettingOption;
