
const config = {
    // Pro:

    apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8000/api/', // Default to production API if not set
    baseUrl:process.env.REACT_APP_BASE_URL || 'http://localhost:8000',
    websocketUrl:process.env.REACT_APP_WEBHOOK_URL||'ws://localhost:8000/ws/',

    // baseUrl:'http://127.0.0.1:8000',
    clientId: '660970705625-l3vva2ba4e4sot6v3pjoui16sje5hjhj.apps.googleusercontent.com',
    mapKey:'AIzaSyAxFqSrDHfOa3Fitprtj6mjyenQj7U_WAs',
    calendlyClientId:'jF8km2jdGbzRSatjPvRxx8FP4Ovwu8OiYW8jRWz7Wwg',
    calendlyClientSecret:'UR5G7H-RGxjdb7q30qO93SURqakc4rtBvb4GR28VVvc',
    calendlyWebhookSigningKey:'OPJbe-WS4B9gK65EzrRcFKfE1X7CNJB1LBpnSUy2KLo',
    calendlyReturnUri:'https://crm.jaweb.me/integration/calendly',
    debugMode: true,
  };
  console.log(`Running in ${config.environment} mode.`);

  export default config;


