import React, { useEffect, useState } from 'react';
import { ExceptionOutlined, InfoCircleOutlined } from '@ant-design/icons';

const PermissionRoute = ({ element: Component, permissionKey }) => {
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    const isInvited = localStorage.getItem('invited') === 'true';

    if (isInvited) {
      setPermissions({
        analytics: true,
        message: true,
        accountSettings: true,
        team: true,
        welcometour:true,
        editprofile:true,
        changepassword:true,
        notifications:true,
        messages:true,
        workflow:true,
        testing_v1:true

      });
    } else {
      setPermissions({
        analytics: true,
        message: true,
        accountSettings: true,
        team: true,
        leads: true,
        integration: true,
        customization: true,
        bottraining: true,
        help: true,
        zapier:true,
        website:true,
        shopify:true,
        calendly:true,
        whatsapp:true,
        wordpress:true,
        editprofile:true,
        welcometour:true,
        changepassword:true,
        transactions:true,
        emailsummary:true,
        instagram:true,
        chatbot:true,
        plans:true,
        notifications:true,
        messages:true,
        shopify_integration:true,
        workflow:true,
        testing_v1:true

      });
    }
  }, []);

  if (!permissions) {
    return <div>Loading...</div>; // Show a loading state while permissions are being determined
  }

  if (!permissions[permissionKey]) {
    return (
      <div className="flex flex-col justify-center items-center text-center h-screen">
        <ExceptionOutlined
          style={{ fontSize: '40px', color: 'rgb(127, 40, 248)' }}
          className="mb-4"
        />
        <h2 className="font-bold text-lg md:text-xl lg:text-2xl">Permission Denied</h2>
        <p className="text-base md:text-lg lg:text-xl mt-4 max-w-md">
          This page is not available for this account.
        </p>
        <InfoCircleOutlined
          style={{
            fontSize: '20px',
            color: 'rgb(127, 40, 248)',
            marginTop: '20px',
            marginBottom: '10px',
          }}
        />
        <a
		  href='/user/Adminusers'
          
            className="inline-flex items-center px-4 py-2 text-white border border-['rgb(127, 40, 248)']  rounded-full hover:bg-indigo-700 focus:outline-none focus:ring" style={{backgroundColor:'rgb(127, 40, 248)',}}>
          
                <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mr-2" fill="white" viewBox="0 0 24 24"
                stroke="white" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
              </svg>
      
	  		
                <a className="text-sm font-medium text-white" >
                  <span className='text-white'>
                  Return to the team

                    </span>
              </a>
		
          </a>
      </div>
    );
  }

  // Render the component if permission is granted
  return <Component />;
};

export default PermissionRoute;
