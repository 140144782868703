import React, { useRef, useState, useEffect } from "react";
import './Css/FileUpload.css'
import axios from "axios";
import { Spin, message } from "antd";
import {
  XMarkIcon, CheckIcon, FolderIcon, DocumentIcon
} from '@heroicons/react/20/solid'
import config from "../../config";
import { Miniupload, PdfIcon, LoaderIcon } from "../../assets/svg/icon.js";
import Websitescraper from "./Websitescraper";
import { DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next";

const FileUpload = ({ onFileUpload, username, setDataLoaded, websiteScrapped, fetchedLinks, startCount }) => {
  const inputRef = useRef();
  const dropRef = useRef();

  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");
  const [uploadStartDate, setUploadStartDate] = useState(null);
  const [uploadedFile, setUploadedFile] = useState([])
  const [isClosed, setIsClosed] = useState(false);
  const [files, setFiles] = useState([]);

  const { t } = useTranslation();
  const botTrainingTranslations = t("botTraining")


  useEffect(() => {
    let timeout;
    if (progress === 100 && !isClosed) {
      timeout = setTimeout(() => {
        setIsClosed(true);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [progress, isClosed]);

  useEffect(() => {
    if (selectedFile) {
      setIsClosed(false);
    }
  }, [selectedFile]);

  const deleteUploadedFile = (id) => {
    const updatedFiles = uploadedFile.filter(file => file.id !== id);
    setUploadedFile(updatedFiles);
  };



  const handleFileChange = async (file) => {
    if (!file) {
      return;
    }

    // Supported types: PDF and TXT
    const supportedFileTypes = ["application/pdf", "text/plain"];
    if (!supportedFileTypes.includes(file.type)) {
      message.error({ content: "Unsupported file type, Only PDF and TXT files are allowed.", duration: 2 });
      return;
    }

    if (file.size <= 1024 * 1024) {
      setSelectedFile(file);
      setProgress(0);
      setUploadStatus("uploading");
      setUploadStartDate(new Date());
      try {
        const formData = new FormData();
        formData.append("name", file);
        formData.append("user", localStorage.getItem("username"));
        console.log('file', file, formData, file.name)
        setUploadedFile([...uploadedFile, { file_name: file.name, date: getCurrentDate() }])

        const response = await axios.post(`${config.apiUrl}upload/`, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        });

        console.log(response.data);
        message.success({ content: "File Uploaded Successfully", duration: 2 });
        setUploadStatus("done");
        onFileUpload();
        setDataLoaded(true);
      } catch (error) {
        if (error.response && error.response.status === 415) {
          // Unsupported Media Type
          message.error({ content: "Unsupported file type", duration: 2 });
        } else {
          console.error("Error uploading file:", error);
          message.error({ content: "Failed to upload file", duration: 2 });
        }
        setUploadStatus("select");
      }
    } else {
      message.error({ content: "File must be less than 1MB", duration: 2 });
    }
  };



  const getCurrentDate = (date) => {
    const currentDate = date || new Date();
    return `${currentDate.getDate()} ${currentDate.toLocaleString('default', { month: 'short' })} ${currentDate.getFullYear()}`;
  };

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const clearFileInput = () => {
    inputRef.current.value = "";
    setSelectedFile(null);
    setProgress(0);
    setUploadStatus("select");
  };

  const handleStartCount = (data) => {
    startCount(data)
  }

  const handleIsWebsiteScrapped = (data) => {
    websiteScrapped(data)
  }
  const handleIsLinksFetched = (data) => {
    fetchedLinks(data)
  }

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   const file = e.dataTransfer.files[0];
  //   handleFileChange(file);
  // };
  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    Array.from(files).forEach((file) => {
      handleFileChange(file);
    });
  };
  return (
    <div className="mb-1 mt-4 padd-15" ref={dropRef} onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
      <input
        ref={inputRef}
        type="file"
        onChange={(e) => handleFileChange(e.target.files[0])}
        style={{ display: "none" }}
      />

      <button className="file-btn pd-20" onClick={onChooseFile}>
        <Miniupload width={25} className='text-indigo' />
        {botTrainingTranslations.chooseFile}
        <p className="up_para">{botTrainingTranslations.urlLink}</p>
        <Websitescraper  websiteScrapped={handleIsWebsiteScrapped} fetchedLinks={handleIsLinksFetched} startCount={handleStartCount}/>
      </button>
      
      {selectedFile && !isClosed && (
        <div className="upload-file-card">
          <div className="flex items-center gap-5">
            <div className="leftPdf-img">
              <PdfIcon width={25} style={{ color: '#7460ff' }} />
            </div>
            <div className="rightPdf-img">
              <div className="filetest mb-3">
                <h6 className="file_name">{selectedFile?.name}</h6>
                <button onClick={clearFileInput} className="">
                  <XMarkIcon width={25} className='text-indigo' />
                </button>
              </div>
              <div className="flex items-center gap-1">
                <span className="datespan">{uploadStartDate ? getCurrentDate(uploadStartDate) : null}</span>
                <div className="flex items-center gap-1">
                  {uploadStatus === 'uploading' ? <Spin size="small" /> : null}
                  <h6 className="mb-0">{uploadStatus === 'uploading' ? 'Uploading...' : null}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="progress-bg">
            <div className="progress" style={{ width: `${progress}%` }} />
          </div>
        </div>
      )}
      {/* {uploadedFile.length > 0 && uploadedFile.map((item) => {
        return (
          <UploadedFile data={item} onDelete={deleteUploadedFile} />
        )
      })} */}
    </div>
  );
};

export default FileUpload