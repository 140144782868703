import React, { useState, useEffect } from 'react';
import { Card, Button, Typography, Input, Layout, notification, Tabs, Select, Steps, Drawer, Modal } from 'antd';
import Draggable from 'react-draggable';
import { EditOutlined, PlusOutlined, SaveOutlined, NotificationOutlined, DeleteOutlined, FileAddOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import './css/Workflow.css';
import CustomLayout from '../../layouts/Customlayout';
import config from '../../config';

const { Text } = Typography;
const { Sider, Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;
const { Step } = Steps;

const toolbarOptions = [
  [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'align': [] }],
  ['link', 'image', 'video'],
  ['clean']  // Remove formatting button
];

function Index() {
  const [activeTab, setActiveTab] = useState('chatbot');
  const [chatbotNodes, setChatbotNodes] = useState([]);
  const [whatsappNodes, setWhatsAppNodes] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [workflowName, setWorkflowName] = useState('');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [keywords, setKeywords] = useState([]);

  const currentNodes = activeTab === 'chatbot' ? chatbotNodes : whatsappNodes;
  const setCurrentNodes = activeTab === 'chatbot' ? setChatbotNodes : setWhatsAppNodes;

  useEffect(() => {
    // Function to fetch workflows from the API
    const fetchWorkflows = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json',
        };
        const response = await fetch(`${config.apiUrl}workflows/`, { headers });
        if (response.ok) {
          const data = await response.json();
          setWorkflows(data);
        } else {
          notification.error({ message: 'Failed to fetch workflows' });
        }
      } catch (error) {
        notification.error({ message: 'An error occurred while fetching workflows' });
      }
    };

    fetchWorkflows();
  }, []);

  // Function to handle workflow selection
  const handleWorkflowSelect = (value) => {
    setSelectedWorkflow(value);
    const workflow = workflows.find((wf) => wf.id === value);
    if (workflow) {
      setCurrentNodes(workflow.nodes);
      setWorkflowName(workflow.name);
      setKeywords(workflow.keywords || []);
      setCurrentStep(4);
    }
  };

  // Function to add new nodes
  const addNode = (type) => {
    if (currentNodes.some((node) => node.type === type)) {
      notification.warning({ message: `Only one ${type} node is allowed` });
      return;
    }
    const newNode = {
      id: `${currentNodes.length + 1}`,
      type,
      label: `${type} Node`,
      content: '',
      triggerType: '',
      actionType: '',
      x: 100,
      y: 100,
      keywords: [],
    };
    setCurrentNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // Function to save the workflow (POST/PUT)
  const handleSave = async () => {
    if (!selectedWorkflow) {
      setIsDrawerVisible(true);
    } else {
      saveWorkflow();
    }
  };

  const saveWorkflow = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${localStorage.getItem('token')}`,
      'Accept': 'application/json',
    };
    const payload = {
      name: workflowName || (activeTab === 'chatbot' ? 'Chatbot Workflow' : 'WhatsApp Workflow'),
      nodes: currentNodes,
      keywords: keywords,
    };

    try {
      let response;
      if (selectedWorkflow) {
        // Update existing workflow (PUT)
        response = await fetch(`${config.apiUrl}workflows/${selectedWorkflow}/`, {
          method: 'PUT',
          headers,
          body: JSON.stringify(payload),
        });
      } else {
        // Create a new workflow (POST)
        response = await fetch(`${config.apiUrl}workflows/`, {
          method: 'POST',
          headers,
          body: JSON.stringify(payload),
        });
      }

      if (response.ok) {
        const data = await response.json();
        notification.success({ message: 'Workflow saved successfully!' });
        setWorkflows((prev) => {
          if (selectedWorkflow) {
            return prev.map((wf) => (wf.id === data.id ? data : wf));
          } else {
            return [...prev, data];
          }
        });
        setSelectedWorkflow(data.id);
        setIsDrawerVisible(false);
      } else {
        const errorData = await response.json(); // Extract the detailed error response
        notification.error({ 
            message: 'Failed to save workflow',
            description: errorData.detail || 'An unknown error occurred', // Show specific error detail if available
        });
    }      
    } catch (error) {
      console.log(error)
      notification.error({ message: 'An error occurred while saving the workflow' });
    }
  };

  // Function to delete a workflow
  const handleDelete = async () => {
    if (!selectedWorkflow) {
      notification.warning({ message: 'Please select a workflow to delete' });
      return;
    }

    Modal.confirm({
      title: 'Are you sure you want to delete this workflow?',
      onOk: async () => {
        try {
          const headers = {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          };
          const response = await fetch(`${config.apiUrl}workflows/${selectedWorkflow}/`, {
            method: 'DELETE',
            headers,
          });

          if (response.status === 204) {
            notification.success({ message: 'Workflow deleted successfully!' });
            setWorkflows((prev) => prev.filter((wf) => wf.id !== selectedWorkflow));
            setSelectedWorkflow(null);
            setCurrentNodes([]);
            setWorkflowName('');
            setKeywords([]);
          } else {
            notification.error({ message: 'Failed to delete workflow' });
          }
        } catch (error) {
          notification.error({ message: 'An error occurred while deleting the workflow' });
        }
      },
    });
  };

  const handleNodeClick = (node) => {
    setSelectedNode(node);
    setIsEditing(true);
  };

  const handleDragStop = (e, data, id) => {
    setCurrentNodes((prevNodes) =>
      prevNodes.map((node) => (node.id === id ? { ...node, x: data.x, y: data.y } : node))
    );
  };

  const updateNode = (updatedNode) => {
    setCurrentNodes((prevNodes) =>
      prevNodes.map((node) => (node.id === updatedNode.id ? updatedNode : node))
    );
    if (updatedNode.id === '1') {
      setCurrentStep(1);
    } else if (updatedNode.id === '2') {
      setCurrentStep(2);
    } else if (updatedNode.id === '3') {
      setCurrentStep(3);
    }
  };

  const NodeConfigSidebar = ({ node, onClose, onSave }) => {
    const [label, setLabel] = useState(node.label);
    const [content, setContent] = useState(node.content || '');
    const [triggerType, setTriggerType] = useState(node.triggerType || '');
    const [actionType, setActionType] = useState(node.actionType || '');
    const [nodeKeywords, setNodeKeywords] = useState(node.keywords || []);

    const handleSave = () => {
      // Validate keywords format
      const validKeywords = nodeKeywords.every(keyword => keyword.match(/^\s*\w+(\s*\w+)*\s*$/));
      if (!validKeywords) {
        notification.error({ message: 'Invalid keywords format. Please ensure keywords are separated by commas and contain only alphanumeric characters.' });
        return;
      }

      if (nodeKeywords.length > 5) {
        notification.error({ message: 'You can enter a maximum of 5 keywords.' });
        return;
      }

      onSave({
        ...node,
        label,
        content,
        triggerType,
        actionType,
        keywords: nodeKeywords,
      });
    };

    return (
      <div className="node-config-sidebar">
        <h3>Edit Node</h3>
        <Input
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          placeholder="Node Label"
          style={{ marginBottom: '16px' }}
        />

        {node.type === 'Content' && (
          <ReactQuill
            value={content}
            onChange={setContent}
            style={{ marginBottom: '16px', height: '' }}
            modules={{ toolbar: toolbarOptions }}
          />
        )}

        {node.type === 'Trigger' && (
          <>
            <Select
              value={triggerType}
              onChange={(value) => setTriggerType(value)}
              style={{ width: '100%', marginBottom: '16px' }}
            >
              <Option value="First Message">First Message</Option>
              <Option value="Message has Keyword">Message has keyword</Option>
            </Select>

            {triggerType === 'Message has Keyword' && (
              <Input
                value={nodeKeywords.join(', ')}
                onChange={(e) => setNodeKeywords(e.target.value.split(',').map((keyword) => keyword.trim()))}
                placeholder="Enter keywords, separated by commas (max 5)"
                style={{ marginBottom: '16px' }}
              />
            )}
          </>
        )}

        {node.type === 'Action' && (
          <Select
            value={actionType}
            onChange={(value) => setActionType(value)}
            style={{ width: '100%', marginBottom: '16px' }}
          >
            <Option value="Send Message">Send Message</Option>
          </Select>
        )}

        <div className='mt-4'>
        <Button type="primary" onClick={handleSave} block>
          Save
        </Button>
        <Button type="default" onClick={onClose} block style={{ marginTop: '8px' }}>
          Cancel
        </Button>
        </div>

      </div>
    );
  };

  // Function to clear the current workflow and start with a clean sheet
  const handleNewWorkflow = () => {
    setSelectedWorkflow(null);
    setCurrentStep(0);
    setCurrentNodes([]);
    setWorkflowName('');
    setKeywords([]);
    notification.info({ message: 'New clean workflow started' });
  };

  return (
    <CustomLayout>
      <Layout style={{ height: '100vh', background: '#f9f9f9' }}>
        <Sider width={250} style={{ background: '#fff', padding: '16px' }}>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Chatbot" key="chatbot">
              <Button type="primary" className="mb-4" icon={<PlusOutlined />} onClick={() => addNode('Trigger')} block>
                Add Trigger
              </Button>
              <Button type="dashed" className="mb-4" icon={<PlusOutlined />} onClick={() => addNode('Content')} block>
                Add Content
              </Button>
              <Button type="default" className="mb-4" icon={<PlusOutlined />} onClick={() => addNode('Action')} block>
                Add Action
              </Button>
              <Button type="primary" className="mb-4" icon={<SaveOutlined />} onClick={handleSave} block>
                Save Workflow
              </Button>
     

              <Select
                placeholder="Select a Workflow"
                style={{ width: '100%', marginTop: '16px' }}
                onChange={handleWorkflowSelect}
                value={selectedWorkflow}
              >
                {workflows.map((workflow) => (
                  <Option key={workflow.id} value={workflow.id}>
                    {workflow.name}
                  </Option>
                ))}
              </Select>
            </TabPane>
          </Tabs>
        </Sider>

        <Content className="workflow-content" style={{ padding: '20px', position: 'relative' }}>
          <div className='flex gap-6'>
          <Button type="default" className="mb-4" icon={<FileAddOutlined />} onClick={handleNewWorkflow} block>
                New Workflow
              </Button>
              <Button type="default" className="mb-4" icon={<DeleteOutlined />} onClick={handleDelete} block>
                Delete Workflow
          </Button>
          </div>

          <Steps current={currentStep} style={{ padding: '20px' }}>
            <Step title="Step 1" description="Edit Trigger node." />
            <Step title="Step 2" description="Edit Content nodes." />
            <Step title="Step 3" description="Edit Action nodes." />
            <Step title="Step 4" description="Save your workflow." />
          </Steps>

          {currentNodes.map((node) => (
            <Draggable
              key={node.id}
              position={{ x: node.x, y: node.y }}
              onStop={(e, data) => handleDragStop(e, data, node.id)}
              bounds=".workflow-content"
            >

              <div className="workflow-card-wrapper" onClick={() => handleNodeClick(node)}>
                <Card title={<Text strong>{node.icon} {node.label}</Text>} bordered={false} className="workflow-card">
                  {node.type === 'Content' && 
                    <div
                      dangerouslySetInnerHTML={{ __html: node.content }}
                      style={{
                        wordWrap: 'break-word',
                        fontSize: '13px',
                        color: 'black',
                        maxHeight: '140px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    />
                  }
                  {node.type === 'Trigger' && <Text>Trigger Type: {node.triggerType}</Text>}
                  {node.type === 'Action' && <Text>Action Type: {node.actionType}</Text>}
                </Card>
              </div>
            </Draggable>
          ))}

          {isEditing && selectedNode && (
            <NodeConfigSidebar
              node={selectedNode}
              onClose={() => setIsEditing(false)}
              onSave={(updatedNode) => {
                updateNode(updatedNode);
                setIsEditing(false);
              }}
            />
          )}
        </Content>

        <Drawer
          title="Save Workflow"
          width={360}
          onClose={() => setIsDrawerVisible(false)}
          visible={isDrawerVisible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Input
            placeholder="Enter Workflow Name"
            value={workflowName}
            onChange={(e) => setWorkflowName(e.target.value)}
            autoFocus
            style={{ marginBottom: '16px' }}
          />
          <Button type="primary" onClick={saveWorkflow} block>
            Save
          </Button>
          <Button type="default" onClick={() => setIsDrawerVisible(false)} block style={{ marginTop: '8px' }}>
            Cancel
          </Button>
        </Drawer>
      </Layout>
    </CustomLayout>
  );
}

export default Index;
