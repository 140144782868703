import React, { useState, useCallback, useEffect } from 'react';
import searchIcon from '../../assets/botTrainingIcons/searchIcon.svg';
import downCircleArrowIcon from '../../assets/botTrainingIcons/Downcircle.svg';
import loadingIcon from '../../assets/botTrainingIcons/loadingIcon.svg';
import declineIcon from '../../assets/botTrainingIcons/declineIcon.svg';
import checkIcon from '../../assets/botTrainingIcons/checkIcon.svg';
import downArrowIcon from '../../assets/botTrainingIcons/downArrowIcon.svg';
import refreshIcon from '../../assets/botTrainingIcons/refreshIcon.svg';
import moreOptionIcon from '../../assets/botTrainingIcons/moreOptionIcon.svg';
import deleteIcon from '../../assets/botTrainingIcons/deleteIcon.svg';
import { message } from "antd";
import axios from 'axios';
import config from '../../config';

const LinksComponent = () => {
  const [individualLinks, setIndividualLinks] = useState(['']);
  const [baseUrl, setBaseUrl] = useState('');
  const [nestedUrls, setNestedUrls] = useState([]);
  const [linksCount, setLinksCount] = useState(0);
  const [characters, setCharacters] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);
  const [checkedUrls, setCheckedUrls] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUrls, setFilteredUrls] = useState([]);
  const [disableTrainingBtn, setDisableTrainingBtn] = useState(false);
  const [tableDataStatus, setTableDataStatus] = useState('')
  const [linksCrawling, setLinksCrawling] = useState(false);


  const CHARACTER_LIMIT = 400000;

  useEffect(() => {
    const filtered = nestedUrls.filter(link => 
      link.url.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUrls(filtered);
  }, [searchTerm, nestedUrls]);

  const updateScrapingInfo = useCallback((data) => {
    if (data.finished) {
      setDisableBtn(false);
      message.success({ content: "Links Fetched Successfully", duration: 2 });
    } else {
      setLinksCount((prevLinks) => prevLinks + 1);
      setCharacters(data.total_characters);
      setNestedUrls((prevLinks) => [{url: data.url, characters: data.characters, lastSynced: new Date(), status:''}, ...prevLinks]);
    }
  }, []);

  const addNewLinkField = () => {
    setIndividualLinks([...individualLinks, '']);    
  };

  const handleIndividualURLChange = (index, value) => {
    const newLinks = [...individualLinks];
    newLinks[index] = value;
    setIndividualLinks(newLinks);
  };

  const handleIndividualURLSubmit = () => {
    const tempURLJson = individualLinks.map(item => ({
      url: item,
      characters: '',
      lastSynced: 'Not Yet',
      status: ''
    }));
    
    setNestedUrls(nestedUrls.concat(tempURLJson));
    setIndividualLinks(['']);
  };

  const handleGetNestedUrls = () => {

    if (nestedUrls.length == 0) { 

      if (baseUrl.length > 0) {
        setDisableBtn(true);
        setLinksCrawling(true)
        const socket = new WebSocket('wss://jawebcrm.onrender.com/ws/total_detected_character/');
        
        socket.addEventListener('open', () => {
          console.log('Connected to the WebSocket');
          socket.send(JSON.stringify({ website_url: baseUrl }));
        });

        socket.addEventListener('message', (event) => {
          const data = JSON.parse(event.data);
          updateScrapingInfo(data);
        });

        socket.addEventListener('close', () => {
          console.log('Disconnected from the WebSocket');
        });

        return () => {
          socket.close();
        };
      } else {
        message.warning({ content: "Enter a url to fetch links.", duration: 2 });
        
      }

    } else {
      message.warning({ content: "You can only scrap one website a time. Delete existing one for next scrap.", duration: 4 });
    }

  };

  const handleCheckboxChange = (url) => {
    setCheckedUrls(prev => {
      const newCheckedUrls = { ...prev, [url]: !prev[url] };
      return newCheckedUrls;
    });
  };

  const handleSelectAllURLs = () => {
    const allChecked = filteredUrls.every(link => checkedUrls[link.url]);
    const newCheckedUrls = { ...checkedUrls };
    filteredUrls.forEach(link => {
      newCheckedUrls[link.url] = !allChecked;
    });
    setCheckedUrls(newCheckedUrls);
  };

  const getSelectedLinks = () => {
    return nestedUrls.filter(link => checkedUrls[link.url]);
  };

  const handleStartTraining = () => {
    const selectedLinks = getSelectedLinks();
    console.log(selectedLinks,'-------------->>selected');
    
    const updatedURLsStatus = selectedLinks.map(item => ({
      ...item,
      status: "SCRAPPING"
    }));
    setNestedUrls(prevUrls => prevUrls.map(url => 
      updatedURLsStatus.find(updated => updated.url === url.url) || url
    ));

    setDisableTrainingBtn(true);
    const socket = new WebSocket('wss://jawebcrm.onrender.com/ws/scrap_website/');
    
    socket.addEventListener('open', () => {
      console.log('Connected to the WebSocket', selectedLinks);
      socket.send(JSON.stringify({ website_url: baseUrl, website_path: JSON.stringify(selectedLinks), user_id:JSON.parse(localStorage.getItem('UserObject')).id}));
    });

    socket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);

      if (data.status === 'COMPLETED') {
        setFilteredUrls(prevUrls => prevUrls.map(url => 
          url.url === data.url ? { ...url, status: data.status } : url
        ));
      }

      if (data.status === 'DONE') {
        setDisableTrainingBtn(false);
        setCheckedUrls({});
        getStoredURLs();
        setLinksCrawling(false)
      }
    });

    socket.addEventListener('close', () => {
      console.log('Disconnected from the WebSocket');
    });

    return () => {
      socket.close();
    };
  };

  const getStoredURLs = () => {
    setTableDataStatus('Loading Data....')

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Accept': 'application/json'
      }
    };
  
    axios.get(`${config.apiUrl}scraped-websites-list-v2/`, headers).then((res) => {
      setNestedUrls(res.data.data);
      console.log(res.data.data);
      if ((res.data.data).length == 0) { 
        setTableDataStatus('No data available')
      }
    }).catch((err) => { 
      setTableDataStatus('No data available')
    });
  };

  useEffect(() => {
    getStoredURLs();
  }, []);

  const handleDeleteSelectedURL = () => {
    const selectedLinks = getSelectedLinks();
    const urlsToDelete = selectedLinks.map(item => item.url);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Accept': 'application/json'
      },
      params: {
        'websites_url': JSON.stringify(urlsToDelete)
      }
    };

    axios.delete(`${config.apiUrl}scraped-websites-list-v2/`, headers)
      .then((res) => {
        setNestedUrls(res.data.remaining_links);
        const newCheckedUrls = { ...checkedUrls };
        urlsToDelete.forEach(url => {
          delete newCheckedUrls[url];
        });
        setCheckedUrls(newCheckedUrls);
        getStoredURLs();
      })
      .catch(error => {
        console.error('Error deleting URLs:', error);
      });
  };

  const formatLastSynced = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    
    const oneDay = 1000 * 60 * 60 * 24;
    const oneMonth = 1000 * 60 * 60 * 24 * 30; 
    const oneYear = 1000 * 60 * 60 * 24 * 365;
    const diffInMs = now - date;

    if (diffInMs < oneDay) {
      return 'Today';
    } else if (diffInMs < oneDay * 2) {
      return 'Yesterday';
    } else if (diffInMs < oneMonth) {
      const daysAgo = Math.floor(diffInMs / oneDay);
      return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
    } else if (diffInMs < oneYear) {
      const monthsAgo = Math.floor(diffInMs / oneMonth);
      return `${monthsAgo} month${monthsAgo > 1 ? 's' : ''} ago`;
    } else {
      const yearsAgo = Math.floor(diffInMs / oneYear);
      return `${yearsAgo} year${yearsAgo > 1 ? 's' : ''} ago`;
    }
  };

  // ////////////////////////////////////////////////////
  

  const handleSingleLinkScrap = (link) => {
       const socket = new WebSocket('wss://jawebcrm.onrender.com/ws/scrap_website/');
    
    socket.addEventListener('open', () => {
      console.log('Connected to the WebSocket for single URL sync');
      socket.send(JSON.stringify({
        website_url: link.url,
        website_path: JSON.stringify([link]),
        user_id: JSON.parse(localStorage.getItem('UserObject')).id
      }));
    });

    socket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);

      if (data.status === 'COMPLETED') {
        setNestedUrls(prevUrls => 
          prevUrls.map(url => 
            url.url === data.url 
              ? { 
                  ...url, 
                  status: data.status,
                  lastSynced: new Date(),
                  characters: data.characters || url.characters
                }
              : url
          )
        );
        message.success({ content: "URL synced successfully", duration: 2 });
      }

      if (data.status === 'CANCELLED') {
        setNestedUrls(prevUrls => 
          prevUrls.map(url => 
            url.url === data.url 
              ? { ...url, status: 'CANCELLED' }
              : url
          )
        );
        message.error({ content: "Sync failed", duration: 2 });
      }
    });

    socket.addEventListener('close', () => {
      console.log('Disconnected from the WebSocket');
    });

    socket.addEventListener('error', (error) => {
      console.error('WebSocket error:', error);
      message.error({ content: "Connection error occurred", duration: 2 });
      setNestedUrls(prevUrls => 
        prevUrls.map(url => 
          url.url === link.url 
            ? { ...url, status: 'CANCELLED' }
            : url
        )
      );
    });

    return () => {
      socket.close();
    };
    
 }


  const handleURLSync = (link) => {
    setNestedUrls(prevUrls => 
      prevUrls.map(url => 
        url.url === link.url 
          ? { ...url, status: "SCRAPPING" }
          : url
      )
    );
    handleSingleLinkScrap(link)
  }


  return (
    <>
      <div className="flex">
        <div className="links-content space-y-6">
          <div className="link-section">
            <h2 className="link-section-title">Crawl Webpages</h2>
            <p className="link-section-description">
              Provide us with a top-level Domain, and we'll crawl all linked pages.
            </p>
            <div className="flex gap-4">
            <input
              type="text"
              placeholder="https://www.xyz.com"
              className="link-input"
              value={baseUrl}
              onChange={(e) => {
                let inputValue = e.target.value.trim();

                // Automatically prepend 'https://' if not already included
                if (inputValue && !inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
                  inputValue = 'https://' + inputValue;
                }
                
                setBaseUrl(inputValue);
              }}
            />
            <button
              className="submit-button"
              onClick={handleGetNestedUrls}
              disabled={disableBtn}
            >
              {disableBtn ? 'Crawling...' : 'Continue'}
            </button>
            </div>
          </div>
        </div>
        
        <div className="train-container">
          <h2 className="train-title">Source</h2>
          <p className="train-link-info">{linksCount} Links ({characters.toLocaleString()} characters detected)</p>
          <h3 className="train-sub-title">Total Detected Characters</h3>
          <p className="train-limit-info">
            <span style={{fontWeight:'bold', fontSize:'13px', color:'#000'}}>{characters.toLocaleString()}</span> / {CHARACTER_LIMIT.toLocaleString()} Limit
          </p>
        </div>
      </div>
    

      {linksCrawling==true && getSelectedLinks().length > 0 && (
        <div className='selectedlinks-container'>
          <div className='selectedlinks-text'>{getSelectedLinks().length} URL(s) selected</div>
          <div className='selectedlinks-buttonContainer'>
            <button
              className={`${'selectedlinks-button'} ${'selectedlinks-startButton'}`}
              onClick={handleStartTraining}
              disabled={disableTrainingBtn}
            >
              Start Training
            </button>
          </div>
        </div>
      )}

      <div className="links-table">
        <div className="links-table-header">
          <div className="links-table-search">
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                className="links-table-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <img src={searchIcon} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" style={{ width: '18px' }} />
            </div>
            
          </div>
            {linksCrawling==false && getSelectedLinks().length > 0 && (
              <div className='link-delete-icon'>
                <img src={deleteIcon} style={{ width: '30px', cursor:'pointer'}} onClick={handleDeleteSelectedURL}/>
              </div>
            )}
        </div>
        
        <div className="overflow-y-auto" style={{ maxHeight: '600px' }}> 
          <table className="links-table-body">
            <thead>
              <tr className="bg-gray-50 text-left text-sm font-medium text-gray-500">
                <th className="links-table-cell">
                  <input
                    type="checkbox"
                    className="rounded"
                    checked={filteredUrls.length > 0 && filteredUrls.every(link => checkedUrls[link.url])}
                    onChange={handleSelectAllURLs}
                  />
                </th>
                <th className="links-table-cell">Links</th>
                <th className="links-table-cell"></th>
                <th className="links-table-cell">Characters</th>
                <th className="links-table-cell">Last Synced</th>
                <th className="links-table-cell">Sync</th>
                <th className="links-table-cell"></th>
              </tr>
            </thead>
     <tbody>
        {filteredUrls.length > 0 ? filteredUrls.map((link) => (
          <tr key={link.url} className="links-table-row">
            <td className="links-table-cell">
              <input
                type="checkbox"
                className="rounded"
                checked={checkedUrls[link.url] || false}
                onChange={() => handleCheckboxChange(link.url)}
              />
            </td>
            <td className="links-table-cell links-table-status">
              {link.url}
            </td>
            <td className="links-table-cell">
              {link.status === 'COMPLETED' && <img src={checkIcon} style={{ width: '20px' }} />}
              {link.status === 'CANCELLED' && <img src={declineIcon} style={{ width: '20px' }} />}
              {link.status === 'SCRAPPING' && <img src={loadingIcon} style={{ width: '20px' }} />}
            </td>
            <td className="links-table-cell">{link.characters}</td>
            <td className="links-table-cell">{formatLastSynced(link.lastSynced)}</td>
            <td className="links-table-cell">
              <div 
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => handleURLSync(link)}
              >
                <img 
                  src={refreshIcon} 
                  style={{ width: '18px' }} 
                  className={link.status === 'SCRAPPING' ? 'opacity-50' : ''}
                />
              </div>
            </td>
          </tr>
        )) : (
          <tr>
            <td colSpan={7} className="text-center text-gray-500">
              {tableDataStatus}
            </td>
          </tr>
        )}
      </tbody>
          </table>
        </div>
      </div>

    </>
  );
};

export default LinksComponent;