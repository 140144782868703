import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { message, Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const EmailLogo = () => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [spin, setSpin] = useState(false);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  const emailSummaryTranslation = t("accountSetting")

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const headers = {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json',
        };
        const res = await axios.get(`${config.apiUrl}email-logo/`, { headers });
        setPreview(res.data.data.email_logo);
      } catch (error) {
        console.error('Error getting data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, []);

  const handleImageUpload = () => {
    setSpin(true);

    const formData = new FormData();
    formData.append('email_logo', image);

    const headers = {
      'Authorization': `Token ${localStorage.getItem('token')}`,
      'Accept': 'application/json',
    };

    axios.post(`${config.apiUrl}email-logo/`, formData, { headers })
      .then(res => {
        message.success('Logo updated successfully');
        setPreview(res.data.data.email_logo);
        setSpin(false);
      })
      .catch(error => {
        message.error('Error saving logo');
        console.error('Error posting data:', error);
        setSpin(false);
      });
  };

  return (
    <div style={styles.container}>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={styles.input}
      />
      {loading ? (
        <Spin style={{ fontSize: 24 }} /> // Reduce loader size here
      ) : (
        preview && (
          <div style={styles.previewContainer}>
            <img src={preview} alt="Preview" style={styles.image} />
          </div>
        )
      )}
      <Button
        key="submit"
        type="primary"
        loading={spin}
        onClick={handleImageUpload}
      >
        {emailSummaryTranslation.emailSummary.uploadImgBtn}
      </Button>
    </div>
  );
};


const styles = {
  container: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    border: '2px dashed #ccc',
    borderRadius: '10px',
    maxWidth: '90%', 
    margin: 'auto',
  },
  input: {
    marginBottom: '20px',
    width: '100%', 
    maxWidth: '300px', 
    boxSizing: 'border-box', 
  },
  previewContainer: {
    marginBottom: '20px',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '300px',
    borderRadius: '10px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default EmailLogo;
