import React, { Component } from 'react';
import { Card, Table, Tooltip, message, Button } from 'antd';
import { EyeOutlined, DeleteOutlined, FileExcelOutlined } from '@ant-design/icons';
import config from '../../config';
import countries from '../../countries';
import dayjs from 'dayjs';
import { exportAsExcel } from './exportUtils';
import UserView from '../UserView';
import CustomLayout from '../../layouts/Customlayout';
import './Leads.css';
import WorldMap from './WorldMap';
import { withTranslation } from 'react-i18next';
import '../../translations/translations.js';

export class UserList extends Component {
  state = {
    users: [],
    userProfileVisible: false,
    selectedUser: null,
    results: [],
  };

  deleteUser = (userId) => {
    this.setState({
      users: this.state.users.filter((item) => item.id !== userId),
    });
    message.success({ content: `Deleted user ${userId}`, duration: 2 });
  };

  showUserProfile = (userInfo) => {
    this.setState({
      userProfileVisible: true,
      selectedUser: userInfo,
    });
  };

  closeUserProfile = () => {
    this.setState({
      userProfileVisible: false,
      selectedUser: null,
    });
  };

  getCountryCode = (countryName) => {
    const uppercaseCountryName = countryName.toUpperCase();
    const country = countries.find((c) => c.name.toUpperCase() === uppercaseCountryName);
    return country ? country.code : '';
  };

  componentDidMount() {
    this.loadUserData();
  }

  loadUserData = async () => {
    try {

      const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
      };


      const response = await fetch(`${config.apiUrl}chatlogs-get/${localStorage.getItem('username')}`, { headers: headers });
      const chatlogs = await response.json();

      this.setState({
        users: chatlogs,
      });

      const countryCounts = {};

      chatlogs.forEach((entry) => {
        const countryName = entry.country;
        if (countryName) {
          countryCounts[countryName] = (countryCounts[countryName] || 0) + 1;
        }
      });

      const result = Object.entries(countryCounts).map(([country, count]) => ({
        color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        name: country.charAt(0).toUpperCase() + country.slice(1),
        value: count.toString(),
      }));

      this.setState({
        results: result,
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  render() {
    const { users, userProfileVisible, selectedUser, results } = this.state;
    const { t } = this.props;

    const tableColumns = [
      {
        title: t('leads.name'),
        dataIndex: 'name',
        render: (name) => (
          <div className="d-flex text-left">
            <span>{name}</span>
          </div>
        ),
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      },
      {
        title: t('leads.date'),
        dataIndex: 'date',
        render: (date) => (
          <div className='text-left'>
            <span>{dayjs(date).format('YYYY-MM-DD / HH:mm:ss')}</span>
          </div>
        ),
        sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
      },
      {
        title: t('leads.country'),
        render: (_, elm) => (
          <div className='text-left'>
            <p>{elm.country}</p>
          </div>
        ),
      },
      {
        title: t('leads.phone'),
        dataIndex: 'phone',
        render: (phone) => (
          <div className='text-left'>
            <span>{phone}</span>
          </div>
        ),
      },
      {
        title: t('leads.action'),
        render: (_, elm) => {
          const isSuperUser = localStorage.getItem("superUser") === "true"; 
          return (
            <div className='text-left'>
              <Tooltip title="View">
                <Button
                  className="mr-2"
                  icon={<EyeOutlined />}
                  onClick={() => { this.showUserProfile(elm) }}
                  size="small"
                />
              </Tooltip>
      
              {isSuperUser && (
                <Tooltip title="Delete">
                  <Button
                    danger
                    className='mr-6'
                    icon={<DeleteOutlined />}
                    onClick={() => { this.deleteUser(elm.id) }}
                    size="small"
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      }
      
    ];

    return (
      <CustomLayout>

        <WorldMap results={results} />

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', marginBottom: '5px' }}>
          <div></div>
          <Button type="primary" className='bg-[#7F28F8]' icon={<FileExcelOutlined />} onClick={() => exportAsExcel(users, 'UserList')}>
            {t('leads.exportExcel')}
          </Button>
        </div>

        <Card bodyStyle={{ padding: '0px' }}>
          <div className="table-responsive">
            <Table columns={tableColumns} dataSource={users} rowKey="id" />
          </div>
          <UserView data={selectedUser} visible={userProfileVisible} close={this.closeUserProfile} />
        </Card>
      </CustomLayout>
    );
  }
}

export default withTranslation()(UserList);
