import React, { useState, useEffect } from "react";
import OnboardingSlideshow from "./OnboardingComponent/OnboardingSlideshow";
import colorJawebLogo from "../../../assets/Onboarding/colorLogo.png";
import { EyeOutlined, EyeInvisibleOutlined, UserOutlined  } from "@ant-design/icons";
import axios from "axios";
import { message, Upload, Avatar } from "antd";
import config from "../../../config";
import { useNavigate, useLocation } from "react-router-dom";
import leftArrow from "../../../assets/arrows/LeftArrowIcon.svg";
import PasswordValidation from "../OnboardingComponent/PasswordValidation";

export default function InvitationSignup() {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentStep, setCurrentStep] = useState(0);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isOtpError, setIsOtpError] = useState(false);
  const [generatedOtp, setGeneratedOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [simulationStep, setSimulationStep] = useState(0); // 0: Validating, 1: Creating Account, 2: Success
  const [usernameError, setUsernameError] = useState("");
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [username, setUsername] = useState("");
  const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);

  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [validations, setValidations] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
    length: false
  });

  const [formData, setFormData] = useState({
    first_name: "",
    image: null,
    email:email,
    password:'',
    confirmPassword:'',
    token:token,
    invitation:token
  });

  const [formErrors, setFormErrors] = useState({
    first_name: '',
    username: '',
    email:email,
    password: '',
    confirmPassword: '',
    token:token,
    invitation:"invitation"
  });
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const handleSignup = (e) => {
    setCurrentStep(currentStep+1)
    e.preventDefault();
    console.log('Form submitted successfully:', formData);
    handleSignupOnSuccessPage()
  };



  const handleSignupOnSuccessPage = async () => {

    if (!formData) {
      throw new Error('No form data found');
    }

    setSimulationStep(1)

    try {
     
      const response = await axios.post(`${config.apiUrl}user/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { token } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('Userdata', JSON.stringify(response.data));
      setSimulationStep(2)
    } catch (error) {
      message.error('Account creation failed');
    } finally {
    }
  };


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    setToken(token);
    
    if (!token) {
      navigate("/auth/login");
    } else {
   
      axios
        .post(`${config.apiUrl}invitation/validate-invitation-token/`, { token })
        .then((response) => {
          setEmail(response.data.email);
          console.log(token)
          setFormData((prevData) => ({
            ...prevData,
            invitation: token,
            token: token,
            email: response.data.email,
          }));

          console.log(formData)

        })
        .catch(() => {
          message.error("Invalid token. Please log in again.");
          navigate("/auth/login");
        });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (timer > 0 && currentStep === 3) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    }
  }, [timer, currentStep]);


 const [debounceTimeout, setDebounceTimeout] = useState(null);

  const checkAvailability = async (field, value) => {
    setIsCheckingAvailability(true); // Show spinner

    try {
      const response = await axios.get(`${config.apiUrl}user/check-availability`, {
        params: { field, value },
      });

      if (response.data.exists) {
        setUsernameError("Username already exists");
        setUsernameAvailable(false);
      } else {
        setUsernameError("");
        setUsernameAvailable(true);
      }
    } catch (error) {
      console.error("Error checking availability:", error);
      setUsernameError("An error occurred. Please try again.");
    } finally {
      setIsCheckingAvailability(false); // Hide spinner
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    setFormData((prev) => ({ ...prev, username: value }));

    // Clear messages if input is empty
    if (value.trim() === "") {
      setUsernameError("");
      setUsernameAvailable(false);
      return;
    }

    // Debounce API call
    clearTimeout(debounceTimeout);
    const timeout = setTimeout(() => {
      checkAvailability("username", value);
    }, 500); // Wait 500ms after the user stops typing
    setDebounceTimeout(timeout);
  };



  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setIsOtpError(false);
    }
  };

  const generateRandomNumberString = (length) => {
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      result += randomDigit;
    }
    return result;
  };

  const handleSendOtp = () => {
    const otp = generateRandomNumberString(6);
    setGeneratedOtp(otp);
    setTimer(60);

    axios
      .post(`${config.apiUrl}user/send-otp`, { email, otp, type: "signup" })
      .then(() => {
        message.success("OTP sent successfully.");
        setCurrentStep(3);
      })
      .catch(() => {
        message.error("Failed to send OTP. Please try again.");
      });
  };

  const handleOtpVerification = () => {
    if (otp.join("") === generatedOtp) {
      message.success("OTP verified successfully.");
      setCurrentStep(currentStep + 1);
    } else {
      setIsOtpError(true);
      message.error("Invalid OTP. Please try again.");
    }
  };

  const handleResendOtp = () => {
    handleSendOtp();
  };

  const setPassword = (password) => {
    setFormData((prevData) => ({
      ...prevData,
      password
    }));
  };


  const TypingEffectMessage = ({ message, keyIndex }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [charIndex, setCharIndex] = useState(0);
  
    useEffect(() => {
      if (charIndex < message.length) {
        const timeout = setTimeout(() => {
          setDisplayedText((prev) => prev + message[charIndex]);
          setCharIndex(charIndex + 1);
        }, 50);
        return () => clearTimeout(timeout);
      }
    }, [charIndex, message]);
  
    useEffect(() => {
      setDisplayedText('');
      setCharIndex(0);
    }, [keyIndex]);
  
    return <span className='font-bold'>{displayedText}</span>;
  };
  
  const AnimatedDots = () => {
    const [dots, setDots] = useState('');
  
    useEffect(() => {
      const interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 500);
      return () => clearInterval(interval);
    }, []);
  
    return <span>{dots}</span>;
  };
  

  return (
    <div className="signup-page-container">
      {window.innerWidth >= 1000 && <OnboardingSlideshow />}
      <div style={{ flex: "1" }}>
        {/* Step 0: Enter Name */}
        {currentStep === 0 && (
          <div className="signup-container">
            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${
                    index <= currentStep ? "signup-progress-step-active" : ""
                  }`}
                />
              ))}
            </div>
            <h1 className="signup-title">What is your First Name?</h1>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="signup-form-group">
                <label className="signup-label">First Name*</label>
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  placeholder="Enter Your First Name"
                  className="signup-input"
                />
              </div>
              <div className="signup-form-group">
                <label className="signup-label">Username*</label>
                <input
                  type="text"
                  name="username"
                  value={username}
                  onChange={handleUsernameChange}
                  placeholder="Enter Your Username"
                  className={`signup-input ${usernameError ? "input-error" : ""}`}
                />
                  <div className="availability-status">
                  {isCheckingAvailability ? (
                    <span className="spinner" />
                  ) : usernameError ? (
                    <p style={{ color: 'red', fontSize: '12px', marginTop: 4 }}>
                      {usernameError}
                    </p>
                  ) : (
                    usernameAvailable && (
                      <p style={{ color: 'green', fontSize: '12px', marginTop: 4 }}>
                        Username "{username}" is available
                      </p>
                    )
                  )}
                </div>

              </div>

              <button
                type="button"
                className="signup-button"
                onClick={() => setCurrentStep(1)}
                disabled={!formData.first_name || !formData.username ||  usernameError || isCheckingAvailability}
              >
                Next
              </button>
            </form>
          </div>
        )}

        {/* Step 1: Upload Profile Picture */}
        {currentStep === 1 && (
  <div className="signup-container">
    <img
      src={leftArrow}
      alt="Back"
      style={{ height: "50px", width: "30px", marginBottom: "50px", cursor: "pointer" }}
      onClick={() => setCurrentStep(0)}
    />
    <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
    <div className="signup-progress-bar">
      {[...Array(6)].map((_, index) => (
        <div
          key={index}
          className={`signup-progress-step ${
            index <= currentStep ? "signup-progress-step-active" : ""
          }`}
        />
      ))}
    </div>
    <h1 className="signup-title">Upload Your Profile Picture</h1>
    <div className="profile-picture-upload flex justify-center items-center">
      {/* Avatar acts as clickable upload */}
      {/* <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        id="upload-input"
        onChange={(e) => {
          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = () => {
              setFormData({
                ...formData,
                image: reader.result, // Store the image URL (base64 string) in formData
              });
            };
            reader.readAsDataURL(file);
          }
        }}
      /> */}
<input
  type="file"
  accept="image/*"
  style={{ display: "none" }}
  id="upload-input"
  onChange={(e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prevData) => ({
          ...prevData,
          image: file, // Store the file for upload
          imagePreview: reader.result, // Store the preview URL
        }));
      };
      reader.readAsDataURL(file); // Convert the file to a data URL
    }
  }}
/>
<label htmlFor="upload-input">
  <Avatar
    size={128}
    src={formData.imagePreview || undefined} // Use the preview URL
    style={{
      border: "3px solid #7F28F8",
      backgroundColor: "#f0f0f0",
      cursor: "pointer",
      marginBottom: 10,
    }}
  >
    {!formData.imagePreview && <UserOutlined style={{color:'black'}}/>} {/* Fallback for no image */}
  </Avatar>
</label>

 
    </div>
    <button disabled={!formData.image} type="button" className="signup-button" onClick={() => setCurrentStep(2)}>
      Next
    </button>
  </div>
)}



        {/* Step 2: Display Email */}
        {currentStep === 2 && (
          <div className="signup-container">
            <img
              src={leftArrow}
              alt="Back"
              style={{ height: "50px", width: "30px", marginBottom: "50px", cursor: "pointer" }}
              onClick={() => setCurrentStep(1)}
            />
            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${
                    index <= currentStep ? "signup-progress-step-active" : ""
                  }`}
                />
              ))}
            </div>
            <h1 className="signup-title">Your Email Address</h1>
            <p className="signup-subtitle">We’ve fetched your email from the token</p>
            <div className="signup-form-group">
              <label className="signup-label">Email Address*</label>
              <input
                type="email"
                value={email}
                disabled
                className="signup-input signup-input-disabled"
              />
            </div>
            <button type="button" className="signup-button" onClick={handleSendOtp}>
              Send OTP
            </button>
          </div>
        )}

        {/* Step 3: OTP Verification */}
        {currentStep === 3 && (
          <div className="signup-container">
            <img
              src={leftArrow}
              alt="Back"
              style={{ height: "50px", width: "30px", marginBottom: "50px", cursor: "pointer" }}
              onClick={() => setCurrentStep(2)}
            />
            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index < 4 ? "signup-progress-step-active" : ""}`}
                />
              ))}
            </div>
            <h1 className="signup-title">Enter OTP</h1>
            <p className="signup-subtitle">We’ve sent a One-Time Password (OTP) to your email.</p>
            <div className="signup-otp-container">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className={`signup-otp-input ${isOtpError ? "otp-input-error" : ""}`}
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                />
              ))}
            </div>
            <p className="signup-timer-text">{timer > 0 ? `${timer} sec` : ""}</p>
            {timer === 0 && (
              <p className="signup-resend-link" onClick={handleResendOtp}>
                Resend OTP
              </p>
            )}
            <button
              type="button"
              className="signup-button"
              onClick={handleOtpVerification}
              disabled={otp.some((digit) => !digit)}
            >
              Verify OTP
            </button>
          </div>
        )}

        {currentStep === 4 && (
          <div className="signup-container">
            <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>

            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index <= currentStep ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">Let's protect your account</h1>
            <p className="signup-subtitle">
              Please create your password
            </p>
            <form onSubmit={handleSignup}>
              <div className="signup-form-group">
                <label className="signup-label">Password*</label>
                <div className="signup-input-wrapper">
           
                  <PasswordValidation password={formData.password} setPassword={setPassword} setValidations={setValidations} validations={validations}/>

                </div>
                {formErrors.password && (
                  <p className="error-message">{formErrors.password}</p>
                )}
              </div>
              <div className="signup-form-group">
                <label className="signup-label">Confirm Password*</label>
                <div className="signup-input-wrapper">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className={`signup-input ${formErrors.confirmPassword ? 'input-error' : ''}`}
                    placeholder="Confirm your password"
                  />
                  <div className="signup-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <EyeOutlined size={20} /> : <EyeInvisibleOutlined size={20} />}
                  </div>
                </div>
                {formErrors.confirmPassword && (
                  <p className="error-message">{formErrors.confirmPassword}</p>
                )}
              </div>
              <button 
                type="submit" 
                className="signup-button"
                disabled={(!formData.password || !formData.confirmPassword) || (formData.password !== formData.confirmPassword)}
              >
                Confirm
              </button>
            </form>
          </div>
        )}


    {currentStep === 5 && (
      <div
        className={`signup-container flex flex-col items-center justify-center min-h-screen transition-colors duration-1000 ${
          simulationStep === 0
            ? 'bg-white' // Light purple for validation
            : simulationStep === 1
            ? 'bg-white' // Medium purple for creation
            : 'bg-white' // Theme purple for success
        }`}
      >
        <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo mb-6" />

        {/* Progress Bar */}
        <div className="signup-progress-bar flex gap-2 mb-6">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className={`signup-progress-step ${
                index <= currentStep ? 'signup-progress-step-active' : ''
              }`}
            />
          ))}
        </div>

        {/* Simulated Process */}
        <div className="flex flex-col items-center text-center">
          {simulationStep === 1 && (
            <p className="text-lg text-gray-800 mb-4">
              <TypingEffectMessage
                message="Your account is being created"
                keyIndex={simulationStep}
              />
              <AnimatedDots />
            </p>
          )}
          {simulationStep === 2 && (
            <div>
              <h2 className="text-2xl font-bold mb-4 text-black">🎉 Account Created Successfully!</h2>
              <p className="mb-6 text-lg text-black">
                Your Jaweb account is ready to go. We're excited to have you on board!
              </p>
              <button
                onClick={() => navigate('/user/leads')}
                className="mb-4 px-3 py-2 bg-[#7F28F8] hover:bg-gray-200 text-[white] font-semibold rounded transition duration-300"
              >
                Go to Dashboard
              </button>
             
            </div>
          )}
        </div>
      </div>
    )}

      </div>
    </div>
  );
}
